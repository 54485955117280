import React from 'react';
import '../../assets/scss/IndexPage.scss';
import '../../assets/scss/typography.scss'
import '../../assets/css/layout.css'

const Page = ({ children }) => {
	return <div className='page'>

		<div className='sub-page'>{children}</div>

	</div>
}

export default Page